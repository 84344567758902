// GENERAL
.color-white {
  color: #fff;
}

// DASHBOARD


// CONTAIN GENERAL ALLS
.contain {
  background-color: white;
  padding: 2rem;
  -webkit-box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);

}

// FORMS
.form-add-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 2rem;
  
  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-right: 2rem;
    grid-gap: 1rem;

  }
}
.form-add-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 2rem;
  
  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-right: 2rem;
    grid-gap: 1rem;

    .container-option {
      position: relative;
    }
  }
}
.form-add-role {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  
  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    .container-option {
      position: relative;
    }
  }
    .table {
      margin-top: 0;
    }
}
.modal-add-role {
  top: 5%;
  
  & > div {
    padding: 1rem 2rem;
  }
}

.alert {
  margin-top: 1rem;
}

// LOGIN
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  height: 110vh;
  width: 100vw;
  margin-top: -10vh;
  background-image: url('../assets/images/Fondo.jpg');
  background-size: cover;
  // background: linear-gradient(to bottom, #2F3947 50%, red 50%);
  
  .logo {
    width: 80%;
    margin-bottom: 2rem;
  }
  .form {
    background-color: rgb(255 255 255 / 40%);
    padding: 0 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    align-items: center;
    width: 80vw;
    border: 1px solid white;

    .title {
      font-size: 28px;
    }
    .container-input {
      width: 100%;
      margin-top: 1rem;

      .input {
        font-size: 14px;
      }
    }
    button {
      background-color: var(--color-dark-blue);
      margin-top: 3rem;
      width: 100%;
      border-radius: 20px;
      font-size: 1rem;

      &:hover {
        background-color: var(--color-dark-blue-hover);
      }
    }
    a {
      text-decoration: none;
      color: var(--color-dark-blue);
      font-size: 14px;
      margin-top: .5rem;
    }
  }
}

// RECOVERY
.recovery {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  height: 110vh;
  width: 100vw;
  margin-top: -10vh;
  background-image: url('../assets/images/Fondo.jpg');
  background-size: cover;
  // background: linear-gradient(to bottom, #2F3947 50%, red 50%);
  
  .logo {
    width: 80%;
    margin: 2rem;
  }
  .form {
    background-color: #fff;
    padding: 0 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    align-items: center;
    width: 80vw;
    border: 1px solid white;
    box-shadow: var(--shadow-grey);

    .title {
      font-weight: 500;
      font-size: 24px;
      text-align: center;
    }
    .subtitle {
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      text-align: center;
    }
    .border {
      width: 100%;
      margin-top: 1rem;
      border-bottom: 1px solid #f4f4f4;
    }
    .container-input {
      width: 100%;
      margin-top: 1rem;
      flex-direction: initial;
      align-items: center;
      justify-content: center;

      .label {
        font-size: 12px;
      }
      .input {
        font-size: 13px;
        width: 75%;
      }
    }
    button {
      background-color: var(--color-dark-blue);
      margin-top: 2rem;
      width: auto;
      padding: 10px 2rem;
      border-radius: 20px;
      font-size: 16px;

      &:hover {
        background-color: var(--color-dark-blue-hover);
      }
    }
    a {
      text-decoration: none;
      color: var(--color-dark-blue);
      font-size: 14px;
      margin-top: .5rem;
    }
  }
}

@media screen and (min-width: 450px) {
  
  .login {

    .form {
      width: 70vw;
    }
  }

}

@media screen and (min-width: 500px) {
  
  .recovery {

    .form {
      
      .container-input {

        .input {
          width: 65%;
        }
      }
    }
  }
  
}

@media screen and (min-width: 550px) {
  
  .login {

    .logo {
      width: 70%;
    }
    .form {
      width: 60vw;
    }
  }
  
  .recovery {

    .logo {
      width: 70%;
    }
    .form {
      width: 70vw;
    }
  }
  
}

@media screen and (min-width: 700px) {
  
  .login {

    .logo {
      width: 60%;
    }
    .form {
      width: 50vw;
    }
  }

  .recovery {

    .form {

      .title {
        font-size: 28px;
      }
      .subtitle {
        font-size: 16px;
      }
      .container-input {

        .label {
          font-size: 14px;
        }
        .input {
          width: 70%;
          font-size: 14px;
        }
      }
      button {
        font-size: 18px;
      }
      a {
        font-size: 16px;
      }
    }
  }
  
}

@media screen and (min-width: 850px) {
  
  .login {

    .logo {
      width: 50%;
    }
    .form {
      width: 40vw;
    }
  }
  
  .recovery {

    .logo {
      width: 60%;
    }
    .form {
      width: 60vw;
    }
  }
  
}

@media screen and (min-width: 1024px) {
  
  .login {

    .logo {
      width: 40%;
    }
    .form {
      width: 35vw;
    }
  }
  
  .recovery {

    .logo {
      width: 50%;
    }
    .form {
      width: 50vw;
    }
  }
  
}

@media screen and (min-width: 1200px) {
  
  .login {

    .logo {
      width: 35%;
    }
    .form {
      width: 30vw;
      

      .title {
        font-size: 2rem;
      }
      .container-input {

        .input {
          font-size: 1rem;
        }
      }
      button {
        font-size: 18px;
      }
      a {
        margin-top: .8rem;
      }
    }
  }

  .recovery {

    .form {

      .title {
        font-size: 2rem;
      }
      .subtitle {
        font-size: 18px;
      }
      .container-input {

        .label {
          font-size: 16px;
        }
        .input {
          width: 70%;
          font-size: 16px;
        }
      }
      button {
        font-size: 20px;
      }
    }
  }
  
}

@media screen and (min-width: 1400px) {
  
  .recovery {

    .logo {
      width: 40%;
    }
    .form {
      width: 40vw;
    }
  }
  
}

@media screen and (min-width: 1600px) {
  
  .login {

    .logo {
      width: 30%;
    }
    .form {
      width: 25vw;
    }
  }
  
}

@media screen and (min-width: 1800px) {
  
  .login {

    .logo {
      width: 25%;
    }
    .form {
      width: 20vw;
    }
  }
  
  .recovery {

    .logo {
      width: 30%;
    }
    .form {
      width: 30vw;
    }
  }
  
}