:root {
  --color-primary: #00CF79;
  --color-dark-primary: #333f54;
  --color-light-dark-primary: #39465e;
  --color-light-primary: #4b5b78;
  --color-light-grey: #eaeaea;
  --color-dark-white: #f4f7fa;
  --color-sky: #1dc4e9;
  --color-dark-blue: #2F3947;
  --color-dark-blue-hover: #1e242c;
  --color-hover: rgba(0,0,0,.1);
  --shadow-sky: 0 0 0 0.2rem rgba(0, 123, 255, .25);
  --shadow-grey: 0 0 5px 2px rgba(0, 0, 0, .25);
}

body {
  background-color: var(--color-dark-white);
}

ul {
  list-style: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
