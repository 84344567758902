// HEADER
body {

  .header {
    width: 100%;
    height: 3rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
  
    .container {
      height: 100%;
    }
    .contain {
      height: 100%;
    }
    .container-btn-menu {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
  
      &:hover {
        background-color: rgba(0,0,0,.1);
      }
      .btn-menu {
        padding: 0 1rem;
        
      }
    }
    .profile {
      height: 100%;
      display: flex;
      align-items: center;
      grid-gap: .5rem;
      padding: 12px 1rem;
      cursor: pointer;
  
      &:hover {
        background-color: rgba(0,0,0,.1);
      }
      .image {
        background-color: white;
        border-radius: 50%;
        padding: 4px;
        height: 2.5rem;
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
        }
      }
    }
  }
}

// SIDEBAR
.sidebar {
  background-color:#f9fafc;
  min-height: 100vh;

  .logo {
    height: 3rem;
    background-color: var(--color-primary);
    margin: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .categories {
    padding: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #d2d6de;
    margin: 0;
    height: 100%;

    .category {
      position: relative;
      transition: all .3s;
      
      &:hover {
        background-color: rgba(0,0,0,.1);
        transition: all .3s;
      }
      .directory {
        padding: .5rem 1rem 1rem 1rem;
        text-decoration: none;
        color: black;
        display: flex;
        align-items: center;
        grid-gap: 8px;

        &:hover {
          color: black;
        }
        &:hover .icon {
          opacity: 1;
          transition: all .3s;
        }
        .icon {
          opacity: 0;
          font-size: 6px;
          transition: all .3s;
        }
      }
      .text {
        display: inline-block;
        width: 100%;
        position: relative;
        padding: 1rem 0;
        padding-left: 1rem;
        cursor: pointer;
        font-weight: 500;
        
        .icon {
          position: absolute;
          right: 1rem;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
      }
      .directories {
        background-color: var(--color-hover);
        display: flex;
        flex-direction: column;
        padding: 0;
        padding-left: 1rem;
        padding-top: .5rem;

        .directory {
          padding: .5rem 1rem 1rem 1rem;
          text-decoration: none;
          color: black;
          display: flex;
          align-items: center;
          grid-gap: 8px;

          &:hover {
            color: black;
          }
          &:hover .icon {
            opacity: 1;
            transition: all .3s;
          }
          .icon {
            opacity: 0;
            font-size: 6px;
            transition: all .3s;
          }
        }
      }
    }
    .category-active {
      background-color: rgba(0,0,0,.1);

      &::after {
        content: "";
        background-color: black;
        z-index: 1027;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 3px;
        height: calc(100% - 5px);
      }
    }
  }
}

// LAYOUT
.layout {
  display: grid;
  grid-template-columns: 1fr 8fr;

  .main {

    .content {
      padding: 2rem;
    }
  }
}

// TABLE
.table {
  border-spacing: 0;
  margin: 0 auto;
  position: relative;
  margin-top: 4rem;

  .container-filters {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
  
    .container-input {
  
      .input {
        padding-left: 2rem;
      }
    }
    .icon {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
  thead {

    tr {

      th {
        padding: 1rem;
        border-top: 1px solid var(--color-light-grey);
        border-bottom: 1px solid var(--color-light-grey);
      }
    }
  }
  tbody {

    tr {

      .row-data {
        display: flex;
        align-items: center;
        grid-gap: 4px;

        svg {
          font-size: 18px;
          cursor: pointer;
        }
      }
      td {
        border-bottom: 1px solid var(--color-light-grey);
        padding: 1rem;
        text-align: center;

        .icon {
          color: var(--color-light-primary);
          cursor: pointer;
          transition: all .3s;
          
          &:hover {
            color: var(--color-dark-primary);
            transition: all .3s;
          }
        }
      }
      .container-icons {
        display: flex;
        grid-gap: 12px;

        .approve {
          color: green;
        }
        .rejected {
          color: red;
        }
      }
    }
  }
}

// Pagination
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-dark-grey);
  font-weight: 600;
  margin: 1rem;
  
  .contain-pagination {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;

    .next-page {
      position: absolute;
      left: 100%;
    }
    .previous-page {
      position: absolute;
      right: 100%;
    }
    .box {
      height: 2rem;
      width: 2rem;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 6px;
      transition: all .5s;

      &:hover {
        background-color: var(--color-dark-primary);
        color: white;
        transition: all .5s;
      }
    }
    .box-active {
      background-color: var(--color-light-dark-primary);
      color: white;
    }
    .box-points {
      cursor: default;
    }
  }
}

// BUTTON
.button {
  padding: 10px 1rem;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: var(--color-light-primary);
  color: white;
  cursor: pointer;
  transition: all .5s;

  &:hover {
    background-color: var(--color-dark-primary);
    transition: all .5s;
  }
  &:disabled {
    background-color: var(--color-light-grey);
    cursor: default;
  }
}

// MODAL
.modal {
  position: fixed;
  display: inline-table;
  left: 0;
  right: 0;
  top: 10%;
  margin: auto;
  min-width: 302px;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  background-color: white;
  z-index: 99;

  & > h2 {
    background-color: var(--color-dark-white);
    margin: 0;
    padding: 1rem;
    text-align: center;
  }
  & > div {
    padding: 2rem;
  }
}
.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

// INPUT 
.container-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  
  .label {
    text-align: end;
  }
  .input {
    width: 100%;
    border-radius: 20px;
    outline: none;
    padding: 8px 1rem;
    border: 1px solid var(--color-light-grey);
    background-color: #fff;
    
    &:focus {
      box-shadow: var(--shadow-sky);
      border: 1px solid var(--color-sky);
    }
  }
}

// SELECT SEARCH
.select-search {
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 10px;
  padding: .5rem;
  margin-top: .5rem;
  background-color: white;
  box-shadow: var(--shadow-grey);
  overflow-y: auto;
  z-index: 999;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
      background: rgba(4, 0, 255, .30);
  }
  &::-webkit-scrollbar-thumb {
      background: var(--color-primary);
      border-radius: 5px;
  }
  .container-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: .1rem;
    margin-top: .5rem;
    max-height: 100px;

    .option {
      padding: 8px;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background-color: var(--color-light-primary);
        color: white;
      }
    }
  }
}

// CHECKBOX PERSONALITY
.container-checkbox {
  display: flex;
  align-items: center;
  
  .icon-checkbox {
      margin-right: 5px;
  }
  .label-checkbox {
      margin-right: 5px;
  }
  .contain-checkbox {
      position: relative;
      height: 20px;
      width: 20px;
      margin: 0 auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover .input-primary ~ .checkbox-container-primary {
          border: 3px solid var(--color-primary);
      }
      &:hover .input-grey ~ .checkbox-container-grey {
          border: 3px solid var(--color-light-grey);
      }
      &:hover .input-disabled ~ .checkbox-container-disabled {
          border: 2px solid var(--color-light-grey);
      }
      .input-primary {

          &:checked ~ .checkbox .checkbox-primary {
              background-color: var(--color-primary);
              border: none;
          }
      }
      .input-grey {

          &:checked ~ .checkbox .checkbox-grey {
              background-color: var(--color-light-grey);
              border: none;
          }
      }
      .input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          top: 10%;
          left: 0;
          height: 90%;
          width: 90%;
          z-index: 999;
      }
      .input-disabled {
          cursor: initial;
      }
      .checkbox {
          position: absolute;
          top: 10%;
          left: 0;
          height: 90%;
          width: 90%;
          background-color: #eee;
          border-radius: 50%;
          padding: 2px;
          z-index: 998;

          .contain-checkbox {
              width: 100%;
              height: 100%;
              border-radius: 50%;
          }
      }
      .checkbox-container-primary {
          border: 2px solid var(--color-primary);
      }
      .checkbox-container-grey {
          border: 2px solid var(--color-light-grey);
      }
      .checkbox-container-disabled {
          border: 2px solid var(--color-light-grey);
      }
      .disabled-non-pointer {
          cursor: default;
      }
  }
}